<template>
  <div>
    <v-progress-linear fixed color="primary" indeterminate v-if="loading"></v-progress-linear>
    <v-container>
      <v-row>
        <v-col class="px-6" cols="12" md="12">
          <v-row>
            <v-col cols="12" md="12">
              <h2 class="mt-5">Checklisten in Bearbeitung</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <h4 class="mt-0 mb-2 subtitle-2">Offene Checklisten nach Fahrzeugen:</h4>
              <v-expansion-panels
                  v-model="panel"
              >
                <v-expansion-panel
                    v-for="vehicle in vehicles"
                    :key="vehicle._id"
                >
                  <v-expansion-panel-header>
                    <v-row no-gutters>
                      <v-col cols="9">
                        {{ vehicle.name }}
                      </v-col>
                      <v-col
                          cols="3"
                          class="text--secondary"
                      >
                        <v-row
                            no-gutters
                            style="width: 100%"
                        >
                          <v-col cols="6">

                          </v-col>
                          <v-col cols="6">
                          <span class="ml-2">
                            {{ vehicle.plists.length }}
                          </span>
                            <v-icon>
                              mdi-clipboard-list
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels
                        accordion
                        class="px-0">
                      <v-expansion-panel

                          v-for="list in vehicle.plists"
                          :key="list._id"
                      >
                        <v-expansion-panel-header>
                          <v-row no-gutters>
                            <v-col cols="2">
                              <v-icon color="">mdi-clipboard-list</v-icon>
                            </v-col>
                            <v-col cols="10">
                              {{ list.title }}
                            </v-col>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row no-gutters>
                            <v-col cols="5">

                            </v-col>
                            <v-spacer></v-spacer>
                            <v-divider
                                vertical
                                class="mx-4"
                            ></v-divider>
                            <v-col cols="5" v-if="list.editor && list.updatedAt">
                              <p class="caption my-0">
                                Ersteller: <strong>{{ list.editor }}</strong><br>
                                Zuletzt bearbeitet: <strong>{{ list.updatedAt | moment }}</strong>
                              </p>
                            </v-col>
                          </v-row>

                          <v-card-actions class="mt-4">
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="red"
                                @click="confirmDelete(list)"
                            >
                              Löschen
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="openList(list)"
                            >
                              Öffnen
                            </v-btn>
                          </v-card-actions>

                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog
          v-model="dialog.confirmDelete"
          persistent
          max-width="290"
      >
        <v-card v-if="selectedList">
          <v-card-title class="">
            Checkliste {{ selectedList.title }} löschen?
          </v-card-title>
          <v-card-text>Diese Checkliste definiv löschen?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color=""
                text
                @click="dialog.confirmDelete = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                color="red darken-1"
                text
                :loading="loading"
                @click="deleteList()"
            >
              Weiter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
          v-model="snack.state"
          :timeout="snack.timeout"
          :color="snack.color"
          elevation="12"
          top
          right
      >
        {{ snack.message }}
      </v-snackbar>
    </v-container>
  </div>

</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "Open",
  data() {
    return {
      vehicles: null,
      checklists: null,
      loading: true,
      panel: null,
      selectedList: null,
      dialog: {
        confirmDelete: false
      },
      snack: {
        state: false,
        color: 'success',
        timeout: 2000,
        message: 'Checkliste wurde erfolgreich gespeichert!',
      }

    }
  },
  created() {

  },
  mounted() {
    this.getVehicles()
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD.MM.YY HH:mm')
    }
  },
  methods: {
    getVehicles() {
      this.loading = true
      axios
          .get(process.env.VUE_APP_API_URL + '/pchecklists/vehicles/open/')
          .then((response) => {
            this.vehicles = response.data
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.loading = false
          })
    },
    openList(list) {
      this.$router.push({name: 'PopulateChecklist', params: {id: list._id, newList: 'false'}})
    },
    confirmDelete(list) {
      this.selectedList = list
      this.dialog.confirmDelete = true
    },
    deleteList() {
      this.loading = true
      axios.delete(process.env.VUE_APP_API_URL + '/pchecklists/' + this.selectedList._id)
          .then((res) => {
            if (res.status === 204) {
              this.dialog.confirmDelete = false
              this.getVehicles()
              this.snack.message = "Checkliste wurde erfolgreich entfernt!"
              this.snack.state = true
            } else {
              alert('Fehler! Checkliste konnte nicht gelöscht werden.')
            }

          })
          .finally(() => {
            this.loading = false
          })
    }

  }
}
</script>

<style>
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
</style>